import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import CustomMask from "../FieldMasks";

export default function LaboralData(props) {
  const [laboralData, setLaboralData] = useState(props.laboralData);
  const listAnswer = ["Si", "No"];
  const listStates = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Ciudad de México",
    "Coahuila",
    "Colima",
    "Durango",
    "Estado de México",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "Michoacán",
    "Morelos",
    "Nayarit",
    "Nuevo León",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosí",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatán",
    "Zacatecas",
  ];

  const handleChangeInput = (event) => {
    let laboralDataAux = { ...laboralData };
    laboralDataAux[event.target.name] = event.target.value;
    setLaboralData(laboralDataAux);
    props.handleChange(laboralDataAux);
  };

  /*
   */

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="work">¿Tiene un trabajo?</InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={"fullWidth"}
              value={laboralData["work"]}
              name="work"
              id="work"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {listAnswer.map((option, index) => (
                <option key={"work" + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {laboralData["work"] === "Si" ? (
          <>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Nombre de la institución"
                fullWidth
                name="nameInstitution"
                type={"nameInstitution"}
                value={laboralData["nameInstitution"]}
                onChange={(e) => handleChangeInput(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Actividad"
                fullWidth
                name="activity"
                type={"activity"}
                value={laboralData["activity"]}
                onChange={(e) => handleChangeInput(e)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                label="Número de teléfono"
                fullWidth
                name="phone"
                type={"tel"}
                value={laboralData["phone"]}
                onChange={(e) => handleChangeInput(e)}
                InputProps={{
                  inputComponent: CustomMask.TextMaskCustomPhone,
                  inputProps: {
                    component: CustomMask.TextMaskCustomPhone,
                  },
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                label="Correo electrónico"
                fullWidth
                name="email"
                type={"email"}
                value={laboralData["email"]}
                onChange={(e) => handleChangeInput(e)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom component="div">
                Dirección
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                label="Calle"
                fullWidth
                name="direction"
                value={laboralData["direction"]}
                onChange={(e) => handleChangeInput(e)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                label="Colonia"
                fullWidth
                name="colony"
                value={laboralData["colony"]}
                onChange={(e) => handleChangeInput(e)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                size="small"
                label="Ciudad"
                fullWidth
                name="city"
                value={laboralData["city"]}
                onChange={(e) => handleChangeInput(e)}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="state">Estado </InputLabel>
                <Select
                  native
                  fullWidth
                  displayEmpty
                  size="small"
                  label={"fullWidth"}
                  value={laboralData["state"]}
                  name="state"
                  id="state"
                  onChange={(e) => {
                    handleChangeInput(e);
                  }}
                >
                  <option value="" disabled></option>
                  {listStates.map((option, index) => (
                    <option key={"listStates" + index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        ) : null}
      </Grid>
    </Box>
  );
}
