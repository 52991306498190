import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(160),
    display: "flex",
  },
  toolbar: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "14px",
    marginLeft: theme.spacing(2),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

export default function Nav() {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <CssBaseline />
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" noWrap>
          Instituto Williams Keembol
        </Typography>
        <div className={classes.navlinks}>
          <a href="https://institutowilliams.edu.mx/" className={classes.link}>
            REGRESAR
          </a>
        </div>
      </Toolbar>
    </AppBar>
  );
}
