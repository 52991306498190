import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCoWK2tMO6Qry5b7pO22FgpGQMlHDilkTU",
  authDomain: "inscripciones-iwk.firebaseapp.com",
  projectId: "inscripciones-iwk",
  storageBucket: "inscripciones-iwk.appspot.com",
  messagingSenderId: "568812619939",
  appId: "1:568812619939:web:fdb9269f87ace25733d716",
  measurementId: "G-WBBED3E44P",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
