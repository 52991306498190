import { Box, Grid } from '@mui/material';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';

export default function StudyPlan(props) {
  const [studyPlan, setStudyPlan] = useState(props.studyPlan);

  const plans = [
    {
      type: 'Preparatoria',
      plans: ['Preparatoria '],
    },
    {
      type: 'Universidad',
      plans: [
        'Administración de empresas',
        'Derecho',
        'Educación deportiva',
        'Psicología',
        'Ingeniería Civil',
        'Educación',
        'Estilismo',
      ],
    },
    {
      type: 'Posgrado',
      plans: [
        'Maestría en Educación',
        'Maestría en Justicia Penal',
        'Maestría en Derecho Procesal Adversarial Oral',
        'Maestría en Criminología',
        'Maestría en Administración Hospitalaria',
        'Maestría en Juicios Orales en el Sistema Penal Acusatorio',
        'Maestría en Finanzas',
        'Doctorado en Educación',
        'Especialidad en Educación',
      ],
    },
    {
      type: 'Centro de idiomas',
      plans: ['Inglés', 'Francés', 'Tenek'],
    },
  ];

  const modalities = [
    {
      type: 'Preparatoria',
      modality: ['Escolarizada', 'Sabatina', 'Online'],
    },
    {
      type: 'Universidad',
      modality: ['Escolarizada', 'Sabatina', 'Online'],
    },
    {
      type: 'Posgrado',
      modality: ['Viernes', 'Sabado', 'Online'],
    },
    {
      type: 'Centro de idiomas',
      modality: ['Niños', 'Adultos', 'Online'],
    },
  ];

  const sites = ['Tantoyuca', 'Tuxpan', 'Acayucan', 'Tihuatlán', 'Chontla'];

  const handleChangeInput = (event) => {
    let studyPlanAux = { ...studyPlan };

    studyPlanAux[event.target.name] = event.target.value;
    setStudyPlan(studyPlanAux);
    props.handleChange(studyPlanAux);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="name">Plan de estudios</InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={'fullWidth'}
              value={studyPlan['name']}
              name="name"
              id="name"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {plans.map((option, index) => {
                return (
                  <optgroup key={'plansTitle' + index} label={option.type}>
                    {option.plans.map((plan, planKey) => {
                      return (
                        <option
                          key={'plan' + planKey}
                          value={option.type + ' - ' + plan}
                        >
                          {plan}
                        </option>
                      );
                    })}
                  </optgroup>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="modality">Modalidad</InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={'fullWidth'}
              value={studyPlan['modality']}
              name="modality"
              id="modality"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {modalities
                .filter((item) => studyPlan.name.includes(item.type))
                .map((option, index) => {
                  return (
                    <optgroup key={'modalityTitle' + index} label={option.type}>
                      {option.modality.map((modality, planKey) => {
                        return (
                          <option key={'modality' + planKey} value={modality}>
                            {modality}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="site">Sede</InputLabel>
            <Select
              native
              fullWidth
              displayEmpty
              size="small"
              label={'fullWidth'}
              value={studyPlan['site']}
              name="site"
              id="site"
              onChange={(e) => {
                handleChangeInput(e);
              }}
            >
              <option value="" disabled></option>
              {sites.map((option, index) => (
                <option key={'site' + index} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
